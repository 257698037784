import currency from 'currency.js';
import React from 'react';
import { useContextTranslation } from 'ui/translation';
import currencyFormat from 'ui/utils/currencyFormat';
import ExpandableSelect from 'ui/elements/ExpandableSelect';

const VoucherValueSelector: React.FC<{
  expanded: boolean;
  selectedValue?: string;
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  expandSection: () => void;
}> = ({ expanded, selectedValue, onSelect, expandSection }) => {
  const t = useContextTranslation('page.voucher');
  const AVAILABLE_VALUES = {
    25: t(`voucher_amount_as_value`, { value: currencyFormat(currency(25)) }),
    50: t(`voucher_amount_as_value`, { value: currencyFormat(currency(50)) }),
    75: t(`voucher_amount_as_value`, { value: currencyFormat(currency(75)) }),
    100: t(`voucher_amount_as_value`, { value: currencyFormat(currency(100)) }),
    200: t(`voucher_amount_as_value`, { value: currencyFormat(currency(200)) }),
  };

  return (
    <ExpandableSelect
      placeholder={t('voucher_value_select')}
      options={AVAILABLE_VALUES}
      onSelectChange={onSelect}
      expanded={expanded}
      value={selectedValue}
      onClick={expandSection}
    />
  );
};

export default VoucherValueSelector;
