import Rating from 'react-rating';
import { IonIcon } from '@ionic/react';
import { star, starOutline } from 'ionicons/icons';
import { Trans } from 'react-i18next';
import { useContextTranslation } from '../../../../ui/translation';
import { useContext } from 'react';
import { TreatmentTypeDetails } from '../TreatmentResults';
import styles from './EpxertInfo.module.scss';
import classNames from 'classnames';

type ExpertProfileProps = {
  withDetails?: boolean;
  name: string;
  rating: number;
  expertId: string;
  price: number;
};

const ExpertProfile = ({
  withDetails = false,
  name,
  rating,
  expertId,
  price,
}: ExpertProfileProps) => {
  const t = useContextTranslation('page.treatment');
  const { setExpertId } = useContext(TreatmentTypeDetails);

  const expertInfoStyles = classNames(styles.expertInfo, {
    [styles.withDetails]: withDetails,
  });

  return (
    <div className={expertInfoStyles}>
      <div className={styles.name}>{name}</div>
      {withDetails && (
        <button
          type="button"
          className={styles.details}
          onClick={(e) => {
            e.stopPropagation();
            setExpertId(expertId);
          }}
        >
          {t('view_profile')}
        </button>
      )}
      <div className={styles.rating}>
        {(rating / 10).toFixed(1)}
        <Rating
          start={0}
          stop={5}
          initialRating={Math.round(rating / 10)}
          emptySymbol={<IonIcon icon={starOutline} />}
          fullSymbol={<IonIcon icon={star} />}
          readonly
          className={styles.ratingStars}
        />
      </div>
      {price && (
        <div className={styles.price}>
          <Trans t={t} i18nKey="price_from" values={{ price }}>
            From
            <span className={styles.priceLabel}>{{ price }} €</span>
          </Trans>
        </div>
      )}
    </div>
  );
};

export default ExpertProfile;
