import {
  IonButton,
  IonCol,
  IonGrid,
  IonLoading,
  IonRow,
  useIonViewWillEnter,
} from '@ionic/react';
import injectables from 'application/injectables';
import { CredentialsError } from 'application/pages/Login/ICredentialsLoginAdapter';
import type { FormikHelpers } from 'formik';
import { Field, FormikProvider, useFormik, useFormikContext } from 'formik';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';
import IonicField from 'ui/utils/IonicField';
import * as Yup from 'yup';

import {
  ExpiredCodeError,
  InvalidCodeError,
  InvalidEmailError,
} from './IPasswordResetAdapter';
import type IPasswordResetAdapter from './IPasswordResetAdapter';

export interface EmailFormValues {
  email: string;
}

export const PasswordResetEmailForm: React.FC = () => {
  const formik = useFormikContext<EmailFormValues>();
  const { isSubmitting, status } = formik;
  const t = useContextTranslation('page.password_reset');

  useIonViewWillEnter(() => {
    formik.resetForm();
  });

  const statusError = status?.error;
  const statusErrorText =
    statusError &&
    (statusError instanceof InvalidEmailError
      ? t('error_invalid_email')
      : t('error_unknown'));

  return (
    <AutocompleteForm>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <p>{t('instruction')}</p>
          <Field
            component={IonicField}
            name="email"
            type="email"
            inputMode="email"
            autocomplete="email"
            required
            placeholder={t('email_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <IonButton className="submit-button" expand="block" type="submit">
            {t('submit_label')}
          </IonButton>
          {statusErrorText && (
            <div className="form-error">{statusErrorText}</div>
          )}
        </IonCol>
      </IonRow>
      <IonLoading isOpen={isSubmitting} message={t('loading')} />
      <input type="submit" className="invisible-auto-submit" />
    </AutocompleteForm>
  );
};

export interface CodeFormValues {
  code: string;
}

export const PasswordResetCodeForm: React.FC = () => {
  const formik = useFormikContext<CodeFormValues>();
  const { isSubmitting, status } = formik;
  const t = useContextTranslation('page.password_reset');

  useIonViewWillEnter(() => {
    formik.resetForm();
  });

  const statusError = status?.error;
  const statusErrorText =
    statusError &&
    (statusError instanceof CredentialsError
      ? t('error_invalid_email')
      : t('error_unknown'));

  return (
    <AutocompleteForm>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <p>{t('generate_instruction')}</p>
          <Field
            component={IonicField}
            name="code"
            type="text"
            inputMode="text"
            autocomplete="off"
            required
            placeholder={t('code_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <IonButton className="submit-button" expand="block" type="submit">
            {t('generate_label')}
          </IonButton>
          {statusErrorText && (
            <div className="form-error">{statusErrorText}</div>
          )}
        </IonCol>
      </IonRow>
      <IonLoading isOpen={isSubmitting} message={t('loading')} />
      <input type="submit" className="invisible-auto-submit" />
    </AutocompleteForm>
  );
};

const PasswordReset: React.FC = () => {
  const t = useContextTranslation('page.password_reset');
  const [resetEmail, setResetEmail] = React.useState<string>();
  const [finished, setFinished] = React.useState<boolean>(false);

  const emailValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('email_required'))
          .email(t('email_format')),
      }),
    [t],
  );
  const codeValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .required(t('code_required'))
          .min(2, t('code_invalid'))
          .max(16, t('code_invalid')),
      }),
    [t],
  );

  const [adapter] = useInject<IPasswordResetAdapter>(
    injectables.pages.PasswordResetAdapter,
  );

  const onEmailSubmit = React.useCallback<
    (
      values: EmailFormValues,
      helpers: FormikHelpers<EmailFormValues>,
    ) => Promise<void>
  >(
    async (values, helpers) => {
      try {
        await adapter.sendPasswordResetCode(values.email);
        helpers.setStatus(null);
        setResetEmail(values.email);
      } catch (e) {
        if (e instanceof InvalidEmailError) {
          helpers.setErrors({
            email: t('error_invalid_email'),
          });
          return;
        }
        helpers.setStatus({
          error: e,
        });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [adapter],
  );

  const onCodeSubmit = React.useCallback<
    (
      values: CodeFormValues,
      helpers: FormikHelpers<CodeFormValues>,
    ) => Promise<void>
  >(
    async (values, helpers) => {
      if (!resetEmail) {
        helpers.setSubmitting(false);
        return;
      }
      try {
        await adapter.resetPassword(resetEmail, values.code);
        helpers.setStatus(null);
        setFinished(true);
      } catch (e) {
        if (e instanceof InvalidCodeError) {
          helpers.setErrors({
            code: t('error_invalid_code'),
          });
          return;
        }
        if (e instanceof ExpiredCodeError) {
          helpers.setErrors({
            code: t('error_expired_code'),
          });
          return;
        }
        helpers.setStatus({
          error: e,
        });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [adapter, resetEmail],
  );

  const emailFormik = useFormik<EmailFormValues>({
    initialValues: { email: '' },
    onSubmit: onEmailSubmit,
    validationSchema: emailValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const codeFormik = useFormik<CodeFormValues>({
    initialValues: { code: '' },
    onSubmit: onCodeSubmit,
    validationSchema: codeValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  useIonViewWillEnter(() => {
    setFinished(false);
    setResetEmail(undefined);
  });

  return (
    <SubPageLayout>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h1>{t('title')}</h1>
          </IonCol>
        </IonRow>
        {!resetEmail && (
          <FormikProvider value={emailFormik}>
            <PasswordResetEmailForm />
          </FormikProvider>
        )}
        {resetEmail && !finished && (
          <FormikProvider value={codeFormik}>
            <PasswordResetCodeForm />
          </FormikProvider>
        )}
        {finished && (
          <IonRow>
            <IonCol size="12">
              <p>{t('finished_message')}</p>
              <IonButton
                expand="block"
                routerLink="/login"
                routerDirection="back"
              >
                {t('log_in')}
              </IonButton>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </SubPageLayout>
  );
};

export default PasswordReset;
