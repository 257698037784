import { IonCol, IonLoading, IonRow, useIonViewWillEnter } from '@ionic/react';
import { Field, useFormikContext } from 'formik';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';
import AutocompleteForm from 'ui/utils/AutocompleteForm';
import IonicField from 'ui/utils/IonicField';

import { CredentialsError } from './ICredentialsLoginAdapter';
import Button from 'ui/elements/Button/Button';

export interface LoginFormValues {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const formik = useFormikContext<LoginFormValues>();
  const { isSubmitting, status } = formik;
  const t = useContextTranslation('page.login');

  useIonViewWillEnter(() => {
    formik.resetForm();
  });

  const statusError = status?.error;
  const statusErrorText =
    statusError &&
    (statusError instanceof CredentialsError
      ? t('error_invalid_credentials')
      : t('error_unknown'));

  return (
    <AutocompleteForm>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="email"
            type="email"
            inputMode="email"
            autocomplete="email"
            required
            placeholder={t('email_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <Field
            component={IonicField}
            name="password"
            type="password"
            autocomplete="password"
            required
            placeholder={t('password_placeholder')}
            showErrors
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeMd="8" sizeLg="8">
          <Button className="submit-button" type="submit">
            {t('submit_label')}
          </Button>
          {statusErrorText && (
            <div className="form-error">{statusErrorText}</div>
          )}
        </IonCol>
      </IonRow>
      <IonLoading isOpen={isSubmitting} message={t('loading')} />
      <input type="submit" className="invisible-auto-submit" />
    </AutocompleteForm>
  );
};
