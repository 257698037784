import 'application/pages/Treatments/treatment.scss';

import { useLazyQuery } from '@apollo/client';
import { Preferences as Storage } from '@capacitor/preferences';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
  IonText,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';
import type { TreatmentExpert } from 'application/state/TreatmentContext';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import BeforeContentGallery, {
  defaultMedia,
} from 'ui/elements/BeforeContentGallery';
import ButtonSelector from 'ui/elements/ButtonSelector';
import LocationFormatter from 'ui/elements/LocationFormatter';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { ReactComponent as PersonIcon } from 'ui/theme/images/person-icon.svg';
import { TranslatableFormatter, useContextTranslation } from 'ui/translation';

import { GET_SPA_TREATMENT_TYPES } from 'infrastructure/adapters/graphql/TreatmentAdapter';
import BookingFooterWithChild from '../../../ui/elements/BookingFooter/BookingFooterWithNextExpertSlots';
import ExpandableSelect from '../../../ui/elements/ExpandableSelect';
import type { TreatmentDate, TreatmentLocation } from '../../types';
import PostalCodeInput from '../Treatments/PostalCodeInput';
import SearchFirstExpert from '../Treatments/SearchExpertsForTreatment/SearchFirstExpert';
import TreatmentDateSelector from '../Treatments/TreatmentDateSelector';
import Button from '../../../ui/elements/Button/Button';
import styles from 'ui/elements/ExpandableSelect/expandableSelect.module.scss';

const REFERRAL_CODE = 'referral_Code';
const useDetectReferral = () => {
  const location = useLocation();

  useIonViewDidEnter(() => {
    (async () => {
      const urlParams = new URLSearchParams(location.search);
      const referral = urlParams.get('referralCode');

      if (referral) {
        await Storage.set({
          key: REFERRAL_CODE,
          value: referral,
        });
      }
    })().catch(() => {});
  }, []);
};

const TreatmentSelection = () => {
  const t = useContextTranslation('page.treatment');
  const history = useHistory();
  useDetectReferral();

  const {
    setLocation,
    setDate,
    type,
    setNumberOfExperts,
    setIsFirstExpertSelection,
    slotTime,
    experts,
    length,
    location,
    date,
    numberOfExperts,
    setIsSpaBooking,
    isSpaBooking,
    setExpertId,
    setType,
    locationId,
    locationMedia,
  } = useTreatmentBuilder();

  const onPostalCodeChange = useCallback(
    (selectedLocation) => {
      setLocation(selectedLocation);
      setDate(null);
    },
    [setDate, setLocation],
  );

  const hasChosenTreatment = Boolean(
    date &&
      location?.postalCode &&
      (experts?.[0].type || (isSpaBooking && type)),
  );

  const [getLocationSpaType, { data, loading }] = useLazyQuery(
    GET_SPA_TREATMENT_TYPES,
  );

  useEffect(() => {
    if (locationId) {
      void getLocationSpaType({
        variables: {
          locationId,
          pagination: {
            offset: 0,
            limit: 1,
          },
        },
      });
    }
  }, [locationId]);

  const [showDateSelector, setShowDateSelector] = useState(true);

  const onGoBack = (handleGoBack: () => void) => {
    handleGoBack();
  };

  const footerContent = () => {
    if (Number(numberOfExperts) > 1 && hasChosenTreatment) {
      return (
        <div className="booking-footer-slots">
          <div className="text">
            {experts?.[0] && (
              <>
                {t('summary_first_expert', { name: experts[0].name })}
                <br />
                <TranslatableFormatter value={experts[0].type?.name} />
              </>
            )}
          </div>
          <div className="next-container">
            <Button
              color="primary"
              disabled={!hasChosenTreatment}
              onClick={() => {
                history.push('/booking/expert-select');
              }}
            >
              {t('next')}
            </Button>
          </div>
        </div>
      );
    }
    if (hasChosenTreatment) {
      return (
        <BookingFooterWithChild
          onNext={() => {
            history.push(
              isSpaBooking ? '/spa-booking/summary' : '/booking/summary',
            );
          }}
          expertIndex={0}
          nextEnabled={Boolean(hasChosenTreatment && slotTime)}
          treatmentDate={{
            numberOfExperts: numberOfExperts as number,
            location: location as TreatmentLocation,
            locationId,
            date: date as TreatmentDate,
            experts: experts as TreatmentExpert[],
            treatmentTypeIds: type ? [type.id] : [],
          }}
        />
      );
    }
    return <></>;
  };

  const numberOfExpertsLabels = {
    1: t('number_of_experts.one'),
    2: t('number_of_experts.two'),
  };

  const massageTypeLabels = {
    0: t('treatmentType.regular'),
    1: t('treatmentType.spa'),
  };
  const [expanded, setExpanded] = React.useState(false);
  const [typeExpanded, setTypeExpanded] = React.useState(false);

  useIonViewDidEnter(() => {
    // TODO Move to experts array
    setIsFirstExpertSelection(true);
    if (experts?.[0]) {
      setExpertId(experts[0].expertId);
      setType(experts[0].type);
    }
  }, [experts]);

  const locationHasSpaTreatmentTypes =
    data?.spaTreatmentTypes?.locationTreatmentTypes?.[0];

  const treatmentTypeDisabled = Boolean(
    (location?.postalCode.length || 0) < 3 ||
      (locationId && locationHasSpaTreatmentTypes && isSpaBooking === null),
  );

  const containerRef = useRef<HTMLIonContentElement | null>(null);

  if (locationId && (loading || !data)) {
    return <IonSpinner color="secondary" />;
  }

  return (
    <SubPageLayout
      beforeContent={
        <BeforeContentGallery
          media={locationMedia || experts?.[0].type.media || defaultMedia}
        />
      }
      ref={containerRef}
      className="treatment-selector"
      onGoBack={onGoBack}
      footerContent={() => footerContent()}
      withBanner
    >
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" className="treatment-header-wrapper">
            {(!date || !location) && (
              <IonText>
                <h5 className="treatment-header">{t('header')}</h5>
              </IonText>
            )}
          </IonCol>
          <IonCol size="12">
            {locationId && location ? (
              <section
                className={[styles.hasValue, styles.expandableSelect].join(' ')}
              >
                <ButtonSelector
                  onClick={() => {}}
                  icon={<FontAwesomeIcon icon={faHome} />}
                  options={{
                    buttonClassName: styles.expandButton,
                    textClassName: styles.textContainer,
                    iconClassName: styles.iconContainer,
                    arrowClassName: styles.arrowContainer,
                  }}
                >
                  <LocationFormatter location={location} />
                </ButtonSelector>
              </section>
            ) : (
              <PostalCodeInput
                onSelect={onPostalCodeChange}
                location={location}
                disabled={!!locationId}
              />
            )}
            {locationId &&
              data?.spaTreatmentTypes?.locationTreatmentTypes?.[0] && (
                <IonCol size="12">
                  <ExpandableSelect
                    options={massageTypeLabels}
                    className="button-selector"
                    placeholder={t('treatmentType.placeholder')}
                    name="treatmentType"
                    expanded={typeExpanded}
                    value={
                      isSpaBooking === null
                        ? isSpaBooking
                        : Number(isSpaBooking).toString()
                    }
                    onClick={() => setTypeExpanded((prev) => !prev)}
                    onSelectChange={(event) => {
                      setTypeExpanded(false);
                      setIsSpaBooking(
                        Boolean(event.currentTarget.value === '1'),
                      );
                    }}
                    buttonIcon={<PersonIcon />}
                  />
                </IonCol>
              )}
            {(!locationId || (locationId && !isSpaBooking)) && (
              <IonCol size="12">
                <ExpandableSelect
                  options={numberOfExpertsLabels}
                  disabled={treatmentTypeDisabled}
                  placeholder={t('number_of_experts.placeholder')}
                  name="numberOfExperts"
                  expanded={expanded}
                  value={numberOfExperts?.toString()}
                  onClick={() => {
                    if (treatmentTypeDisabled) {
                      return;
                    }
                    setExpanded((prev) => !prev);
                  }}
                  onSelectChange={(event) => {
                    setExpanded(false);
                    setNumberOfExperts(Number(event.currentTarget.value));
                  }}
                  buttonIcon={<PersonIcon />}
                />
              </IonCol>
            )}

            {showDateSelector && (
              <IonCol size="12">
                <TreatmentDateSelector
                  date={date}
                  onSelect={(selectedDate) => {
                    setDate(selectedDate);
                  }}
                  disabled={Boolean(
                    (location?.postalCode.length || 0) < 3 ||
                      (locationId &&
                        locationHasSpaTreatmentTypes &&
                        isSpaBooking === null),
                  )}
                  isSpa={Boolean(isSpaBooking && locationId)}
                />
              </IonCol>
            )}
          </IonCol>
          {!isSpaBooking && (
            <SearchFirstExpert
              params={{
                date,
                length,
                location,
                setShowDateSelector,
                numberOfExperts,
                locationId,
                scroll: () => {
                  if (isPlatform('mobile')) {
                    void containerRef.current?.scrollToPoint(
                      0,
                      //TODO use better way to scroll to 2step header
                      530,
                      425,
                    );
                  }
                },
              }}
            />
          )}
        </IonRow>
        {!location?.postalCode && (
          <IonRow className="ion-justify-content-center treatment-results-container">
            <IonCol className="no-results no-availabilities" size="12">
              <IonText>
                <p className="booking-info">{t('booking_availability_info')}</p>
              </IonText>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </SubPageLayout>
  );
};

export default TreatmentSelection;
