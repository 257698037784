import './Home.scss';

import {
  isPlatform,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { loggingServiceSymbol } from 'application/services/IErrorLoggingService';
import type INotificationService from 'application/services/INotificationService';
import { useAuth } from 'application/state/AuthProvider';
import type IClientMetadataAdapter from 'application/state/IClientMetadataAdapter';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import i18n from 'infrastructure/i18n';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import MainLayout from 'ui/layout/MainLayout';
import { useContextTranslation } from 'ui/translation';

import injectables from '../../injectables';
import type IOnboardingService from '../../services/IOnboardingService';
import LocationTile from 'ui/theme/images/Location.jpeg';
import AtHomeTile from './images/AtHome.jpg';
import VouchersTile from 'ui/theme/images/Voucher.jpg';
import PromoBanner from 'application/pages/Home/PromoBanner';

const Home: React.FC = () => {
  const t = useContextTranslation('page.home');
  const history = useHistory();
  const [onboarding] = useInject<IOnboardingService>(
    injectables.services.OnboardingService,
  );
  const [notificationRequestRejected, setNotificationRequestRejected] =
    React.useState<boolean>(false);
  const [notifications] = useInject<INotificationService>(
    injectables.services.NotificationService,
  );
  const [metadata] = useInject<IClientMetadataAdapter>(
    injectables.state.ClientMetadataAdapter,
  );
  const [loggingAdapter] =
    useInject<IErrorLoggingService>(loggingServiceSymbol);
  const { isAuthenticated } = useAuth();

  useIonViewWillEnter(() => {
    if (onboarding.shouldRedirectToOnboarding() && isPlatform('mobile')) {
      history.replace('/onboarding');
    }
    if (!notifications.getToken() && !notificationRequestRejected) {
      notifications
        .registerDevice(() => {
          if (isAuthenticated) {
            metadata
              .sync({
                notificationToken: notifications.getToken(),
                locale: i18n.language,
              })
              .catch((e) => {
                loggingAdapter.traceException(e);
              });
          }
        })
        .catch(() => {
          setNotificationRequestRejected(true);
        });
    }
  }, [onboarding, notifications]);

  const { clearData } = useTreatmentBuilder();
  useIonViewDidEnter(() => {
    clearData();
  }, []);

  return (
    <MainLayout className="home">
      <Link className="route-type massage" to="/treatments">
        <img
          className="tile-photo"
          src={AtHomeTile}
          alt={t('headers.treatments')}
        />
        <header className="description">
          {t('headers.title')}
          {t('headers.treatments')}
        </header>
      </Link>
      <Link className="route-type location" to="/locations">
        <img
          className="tile-photo"
          src={LocationTile}
          alt={t('headers.treatments')}
        />
        <header className="description">
          {t('headers.title_locations')}
          {t('headers.locations')}
        </header>
      </Link>
      <Link className="route-type voucher" to="/vouchers">
        <img
          className="tile-photo"
          src={VouchersTile}
          alt={t('headers.treatments')}
        />
        <header className="description">
          {t('headers.title_vouchers')}
          {t('headers.vouchers')}
        </header>
      </Link>
      <PromoBanner />
    </MainLayout>
  );
};

export default Home;
