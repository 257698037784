import './style.scss';

import { gql, useQuery } from '@apollo/client';
import { Clipboard } from '@capacitor/clipboard';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIonToast } from '@ionic/react';
import type { TranslatableString } from 'application/types';
import {
  useContextTranslation,
  useTranslateOptionalString,
} from 'ui/translation';

type Banner = {
  promoName: string;
  promoTextOne: TranslatableString;
  promoTextTwo: TranslatableString;
  startDate: Date;
  endDate: Date;
  promoCode: string;
};

const GET_ACTIVE_BANNER = gql`
  query GetActiveBanner {
    activeBanner {
      promoName
      promoTextTwo {
        lang
        value
      }
      promoTextOne {
        value
        lang
      }
      promoCode
    }
  }
`;

const PromoCodeBanner = ({
  atTop,
}: {
  atTop?: boolean;
}): JSX.Element | null => {
  const t = useContextTranslation('layout.banner');

  const translate = useTranslateOptionalString();

  const [showToast] = useIonToast();

  const query = useQuery<{ activeBanner: Banner }>(GET_ACTIVE_BANNER, {
    fetchPolicy: 'cache-and-network',
  });

  const handleShare = async (data: string): Promise<void> => {
    await Clipboard.write({
      string: data,
    });
    await showToast({
      message: t('copy_success'),
      duration: 3000,
      color: 'primary',
    });
  };

  if (query.loading || !query.data?.activeBanner) {
    return null;
  }

  const banner: Banner = query.data.activeBanner;

  return (
    <div className={`mobile-banner${atTop ? ' at-top' : ''}`}>
      <h1 className="headline">{banner.promoName}</h1>
      <button
        className="banner-button"
        type="button"
        aria-label={t('promo code')}
        onClick={() => {
          handleShare(banner.promoCode).catch(() => {});
        }}
      >
        <p>{translate(banner.promoTextOne)}</p>
        <span className="banner-code">{banner.promoCode}</span>
        <FontAwesomeIcon icon={faCopy} className="banner-icon" />
      </button>
      <p>{translate(banner.promoTextTwo)}</p>
    </div>
  );
};

export default PromoCodeBanner;
