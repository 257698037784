import './expertProfile.scss';

import { IonSpinner } from '@ionic/react';
import type IExpertProfileAdapter from 'application/pages/ExpertProfile/IExpertProfileAdapter';
import type { ExpertProfileBeforeContentHandle } from './index';
import { ExpertProfileBeforeContent, ExpertProfileInfo } from './index';
import injectables from 'application/pages/injectables';
import type { ObjectID } from 'application/types';
import { useInject } from 'inversify-hooks';
import { useRef } from 'react';
import CustomModal from 'ui/elements/CustomModal/CustomModal';
import SubPageWrapperForModal from 'ui/elements/CustomModal/SubPageWrapperForModal';

const ExpertProfileModal = ({
  expertId,
  setExpertId,
}: {
  expertId: ObjectID;
  setExpertId: (expertId: ObjectID | null) => void;
}): JSX.Element => {
  const [adapter] = useInject<IExpertProfileAdapter>(
    injectables.ExpertProfileAdapter,
  );

  const profile = adapter.useExpertProfile(expertId);
  const beforeContentRef = useRef<ExpertProfileBeforeContentHandle>(null);

  return (
    <CustomModal
      isOpen={Boolean(expertId)}
      onDidDismiss={(e) => {
        e.stopPropagation();
        setExpertId(null);
      }}
    >
      <SubPageWrapperForModal
        wrapperClassName="expert-profile"
        beforeContent={
          <div className="expert-profile-before-content modal">
            {profile.value && (
              <ExpertProfileBeforeContent
                ref={beforeContentRef}
                profile={profile.value}
                setIsFullscreen={() => {}}
                isFullscreen={false}
              />
            )}
          </div>
        }
        onClose={(e) => {
          e.stopPropagation();
          setExpertId(null);
        }}
      >
        {!profile?.value && profile.loading && <IonSpinner color="secondary" />}
        {profile?.value && (
          <ExpertProfileInfo
            profile={profile.value}
            onClickShowVideo={undefined}
          />
        )}
      </SubPageWrapperForModal>
    </CustomModal>
  );
};

export default ExpertProfileModal;
