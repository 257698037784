import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import {
  useContextTranslation,
  useTranslateOptionalString,
} from 'ui/translation';
import { useIonToast } from '@ionic/react';
import { gql, useQuery } from '@apollo/client';
import { Clipboard } from '@capacitor/clipboard';
import type { TranslatableString } from 'application/types';

// TODO move to utils
type Banner = {
  promoName: string;
  promoTextOne: TranslatableString;
  promoTextTwo: TranslatableString;
  startDate: Date;
  endDate: Date;
  promoCode: string;
};

const GET_ACTIVE_BANNER = gql`
  query GetActiveBanner {
    activeBanner {
      promoName
      promoTextTwo {
        lang
        value
      }
      promoTextOne {
        value
        lang
      }
      promoCode
    }
  }
`;

const PromoBanner = () => {
  const t = useContextTranslation('layout.banner');

  const translate = useTranslateOptionalString();

  const [showToast] = useIonToast();

  // TODO move to utils
  const query = useQuery<{ activeBanner: Banner }>(GET_ACTIVE_BANNER, {
    fetchPolicy: 'cache-and-network',
  });

  const handleShare = async (data: string): Promise<void> => {
    await Clipboard.write({
      string: data,
    });
    await showToast({
      message: t('copy_success'),
      duration: 3000,
      color: 'primary',
    });
  };

  if (query.loading || !query.data?.activeBanner) {
    return null;
  }

  const banner: Banner = query.data.activeBanner;

  return (
    <div className="promo-banner">
      <div className="promo-banner__content">
        <div className="promo-banner__title">{banner.promoName}</div>
        <div className="promo-banner__subtitle">
          {translate(banner.promoTextTwo)}
        </div>
        <button
          className="promo-banner__button"
          type="button"
          aria-label={t('promo code')}
          onClick={() => {
            handleShare(banner.promoCode).catch(() => {});
          }}
        >
          {translate(banner.promoTextOne)} {banner.promoCode}{' '}
          <FontAwesomeIcon icon={faCopy} className="banner-icon" />
        </button>
      </div>
    </div>
  );
};

export default PromoBanner;
