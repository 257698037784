import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { ObjectID } from 'application/types';
import { TreatmentTimeRange } from 'application/types';
import type { TimeRange } from 'application/types/utils';
import {
  endOfDay,
  isSameMonth,
  isSameYear,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useMemo } from 'react';

import injectables from '../../injectables';
import type { ITreatmentAdapter } from '../ITreatmentAdapter';

const useDateRanges = (calendarViewDate: Date): TimeRange =>
  useMemo<TimeRange>(() => {
    let fromDate = new Date();

    if (
      !isSameYear(calendarViewDate, fromDate) ||
      !isSameMonth(calendarViewDate, fromDate)
    ) {
      fromDate = startOfMonth(calendarViewDate);
    }
    return {
      start: fromDate,
      end: endOfDay(lastDayOfMonth(fromDate)),
    };
  }, [calendarViewDate]);

// TODO remove - unused
const useMinimalPriceForTimeRange = (): ((
  range: TreatmentTimeRange,
) => number | null) => {
  const [service] = useInject<ITreatmentAdapter>(injectables.TreatmentAdapter);
  const { isSpaBooking, locationId } = useTreatmentBuilder();
  const types = service.useTreatmentTypes({ isSpaBooking, locationId });

  return React.useCallback<(range: TreatmentTimeRange) => number | null>(
    (range) => {
      if (types.value) {
        return types.value
          .flatMap((tp) => tp.prices)
          .map((price) => parseFloat(price[range]))
          .reduce<number | null>(
            (min, val) => (!min || val < min ? val : min),
            null,
          );
      }

      return null;
    },
    [types.value],
  );
};

export const useMinimalPriceForExpert = (): ((
  expertTypes: ObjectID[],
  range?: TreatmentTimeRange,
) => number) => {
  const [service] = useInject<ITreatmentAdapter>(injectables.TreatmentAdapter);
  const types = service.useTreatmentTypes();

  return React.useCallback<
    (expertTypes: ObjectID[], range?: TreatmentTimeRange) => number
  >(
    (expertTypes, range) => {
      if (types.value) {
        const intersectionTypes = types.value.filter((el) =>
          expertTypes.includes(el.id),
        );

        return intersectionTypes
          .flatMap((tp) => tp.prices)
          .map((price) =>
            parseFloat(price[range || TreatmentTimeRange.Morning]),
          )
          .reduce<number>((min, val) => (!min || val < min ? val : min), 0);
      }
      return 0;
    },
    [types.value],
  );
};

export { useDateRanges, useMinimalPriceForTimeRange };
