import './expertProfile.scss';

import { gql, useQuery } from '@apollo/client';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonButton,
  IonCardContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonText,
  useIonViewWillEnter,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonLoading,
  IonRow,
} from '@ionic/react';
import injectables from 'application/pages/injectables';
import injectablesType from 'application/pages/injectables';
import type { ITreatmentAdapter } from 'application/pages/Treatments/ITreatmentAdapter';
import { TreatmentTypeDetails } from 'application/pages/Treatments/TreatmentResults';
import { useExpertContext } from 'application/state/ExpertContext';
import type { ObjectID, TreatmentType } from 'application/types';
import { format } from 'date-fns';
import { useInject } from 'inversify-hooks';
import { playOutline, star, starOutline } from 'ionicons/icons';
import * as React from 'react';
import { useContext, useRef, useState } from 'react';
import Rating from 'react-rating';
import { useParams } from 'react-router';
import CustomAlert from 'ui/elements/CustomModal/CustomAlert';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { ReactComponent as ShareIcon } from 'ui/theme/images/arrow-forward-up.svg';
import {
  TranslatableFormatter,
  useContextTranslation,
  useTranslateString,
} from 'ui/translation';

import type { IShareAdapter } from 'infrastructure/adapters/native/NativeShareAdapter';
import { useTreatmentBuilder } from '../../state/TreatmentContext';
import type { ExpertProfile } from './IExpertProfileAdapter';
import type IExpertProfileAdapter from './IExpertProfileAdapter';
import { Swiper, SwiperSlide } from 'ui/elements/Swiper/Swiper';

interface ExpertProfileParams {
  expertId: ObjectID;
}

interface ExpertInfoCardProps {
  profile: ExpertProfile;
  onClickShowVideo?: () => () => void;
}

interface ExpertProfileInfoProps extends ExpertInfoCardProps {
  // eslint-disable-next-line react/no-unused-prop-types
  setIsFullscreen?: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  isFullscreen?: boolean;
}

// TODO: refactor required
/* eslint-disable  @typescript-eslint/no-floating-promises */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unsafe-return */

const ExpertInfoCard = ({ profile, onClickShowVideo }: ExpertInfoCardProps) => {
  const t = useContextTranslation('page.expert_profile');
  const translate = useTranslateString();

  const [shareAdapter] = useInject<IShareAdapter>(injectables.ShareAdapter);

  const [showCopiedAlert, setShowCopiedAlert] = useState(false);

  const handleShare = async () => {
    const shareResult = await shareAdapter.handleUrlNativeShare(
      `/expert/${profile.id}`,
    );
    if (shareResult.notifyUser) {
      setShowCopiedAlert(true);
    }
  };

  return (
    <div className="info-card">
      <button
        className="share"
        type="button"
        aria-label={t('share')}
        onClick={() => void handleShare()}
      >
        <ShareIcon />
      </button>
      <h4>{profile.name}</h4>
      {onClickShowVideo && (
        <IonButton size="small" onClick={onClickShowVideo}>
          <IonIcon color="white" size="small" icon={playOutline} slot="start" />
          {t('show_video')}
        </IonButton>
      )}
      <p className="slogan">{translate(profile.slogan)}</p>
      <div className="languages">
        <FontAwesomeIcon icon={faComment} />
        {translate(profile.languages)}
      </div>
      <div className="details-container">
        <div className="cell bookings-count">
          <div className="value">{profile.bookingCount}</div>
          <div className="label">{t('booking_count')}</div>
        </div>
        <div className="cell certification">
          <div className="value">{t('certified')}</div>
          <div className="label">{t('by_soulhouse')}</div>
        </div>
      </div>
      <div className="details-container">
        <div className="cell age">
          <div className="value">{profile.age}</div>
          <div className="label">{t('years_old')}</div>
        </div>
        <div className="cell experience">
          <div className="value">{translate(profile.experience)}</div>
          <div className="label">{t('experience')}</div>
        </div>
      </div>
      <CustomAlert
        isOpen={showCopiedAlert}
        message={t('share_alert')}
        button={t('alert_close')}
        onDidDismiss={() => setShowCopiedAlert(false)}
      />
    </div>
  );
};

const ExpertServices = ({ profile }: { profile: ExpertProfile }) => {
  const t = useContextTranslation('page.expert_profile');
  const [service] = useInject<ITreatmentAdapter>(
    injectablesType.TreatmentAdapter,
  );
  const availableTypes = service.useTreatmentTypes();

  const { setExpertId, setType, setExperts, isFirstExpertSelection, experts } =
    useTreatmentBuilder();
  const { setExpertId: setModalExpertId } = useContext(TreatmentTypeDetails);
  const servicesButtonRef = useRef<HTMLIonColElement | null>(null);

  return (
    <IonCol ref={servicesButtonRef}>
      <h4>{t('services', { name: profile.name })}</h4>
      <IonList className="expert-reviews" lines="none">
        <IonCardContent>
          {profile.treatmentTypes.map((treatmentType) => (
            <IonItem
              button
              mode="ios"
              key={treatmentType.id}
              onClick={() => {
                const selectedType =
                  availableTypes.value?.find(
                    (expertTreatmentType: TreatmentType) =>
                      expertTreatmentType.id === treatmentType.id,
                  ) || null;
                if (selectedType) {
                  setExperts(
                    !isFirstExpertSelection && experts?.[0]
                      ? [
                          experts?.[0],
                          {
                            expertId: profile.id,
                            name: profile.name,
                            profilePictureUrl: profile.profilePicture,
                            type: selectedType,
                          },
                        ]
                      : [
                          {
                            expertId: profile.id,
                            name: profile.name,
                            profilePictureUrl: profile.profilePicture,
                            type: selectedType,
                          },
                        ],
                  );
                }
                setExpertId(profile.id);
                setType(selectedType);
                setModalExpertId(null);
              }}
            >
              <TranslatableFormatter value={treatmentType.name} />
            </IonItem>
          ))}
        </IonCardContent>
      </IonList>
    </IonCol>
  );
};

const GET_EXPERT_REVIEWS = gql`
  query GetExpertReviews($expertId: ID!, $pagination: Pagination!) {
    expertReviewsForClient(expertId: $expertId, pagination: $pagination) {
      nodes {
        id
        rating
        clientData {
          name
        }
        text
        createdAt
      }
      count
    }
  }
`;

type ExpertReview = {
  id: ObjectID;
  rating: number;
  clientData: {
    name: string;
  };
  text: string;
  createdAt: string;
};

const REVIEWS_PER_PAGE = 10;

const ExpertReviews = ({ id }: { id: ObjectID }) => {
  const mt = useContextTranslation('misc');
  const t = useContextTranslation('page.expert_profile');

  const [page, setPage] = useState(1);

  const { data, loading, fetchMore } = useQuery(GET_EXPERT_REVIEWS, {
    variables: {
      expertId: id,
      pagination: {
        limit: REVIEWS_PER_PAGE,
        offset: 0,
      },
    },
  });

  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);

  const disableScroll =
    page * REVIEWS_PER_PAGE >= data?.expertReviewsForClient?.count;

  if (
    loading ||
    !data?.expertReviewsForClient ||
    !data?.expertReviewsForClient?.count
  ) {
    return null;
  }

  const reviews = data?.expertReviewsForClient?.nodes as ExpertReview[];

  return (
    <>
      {reviews.length && <h4>{t('reviews')}</h4>}
      <IonList className="expert-reviews">
        {reviews.map((review) => (
          <IonItem key={review.createdAt}>
            <div className={`review${review.text ? '' : ' offset-bottom'}`}>
              <div className="review-container">
                <IonText className="review-author">
                  {review.clientData.name}
                </IonText>
                <span className="divider" />

                <IonText className="review-date">
                  {format(new Date(review.createdAt), 'dd.MM.yyyy')}
                </IonText>
              </div>
              <div className="rating-container">
                <Rating
                  className="review-rating"
                  start={0}
                  stop={5}
                  fractions={2}
                  initialRating={review.rating}
                  emptySymbol={
                    <IonIcon className="review-icon" icon={starOutline} />
                  }
                  fullSymbol={<IonIcon className="review-icon" icon={star} />}
                  readonly
                />
              </div>
              {review.text && (
                <div className="review-text-container">
                  <IonText className="review-content">{review.text}</IonText>
                </div>
              )}
            </div>
          </IonItem>
        ))}
        <div>
          <IonInfiniteScroll
            ref={infiniteScrollRef}
            threshold="10%"
            disabled={disableScroll}
            onIonInfinite={() => {
              fetchMore({
                variables: {
                  pagination: {
                    limit: REVIEWS_PER_PAGE,
                    offset: page * REVIEWS_PER_PAGE,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    expertReviewsForClient: {
                      count: fetchMoreResult?.expertReviewsForClient?.count,
                      nodes: [
                        ...prev.expertReviewsForClient.nodes,
                        ...fetchMoreResult.expertReviewsForClient.nodes,
                      ],
                    },
                  };
                },
              }).then(() => {
                setPage((p) => p + 1);
              });
            }}
          >
            <IonInfiniteScrollContent loadingText={mt('loading')} />
          </IonInfiniteScroll>
        </div>
      </IonList>
    </>
  );
};

export const ExpertProfileInfo: React.FC<ExpertProfileInfoProps> = ({
  profile,
}) => {
  const t = useContextTranslation('page.expert_profile');
  const translate = useTranslateString();

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12">
          <ExpertInfoCard profile={profile} />
        </IonCol>
        <IonCol className="description" size="12">
          <h4>{t('about', { name: profile.name })}</h4>
          <p>{translate(profile.description)}</p>
        </IonCol>
        <ExpertServices profile={profile} />
        <IonCol size="12">
          <ExpertReviews id={profile.id} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export type ExpertProfileBeforeContentHandle = {
  slideToFirstVideo: () => void;
};

// eslint-disable-next-line react/display-name
export const ExpertProfileBeforeContent = React.forwardRef<
  ExpertProfileBeforeContentHandle,
  ExpertProfileInfoProps
>(({ profile, isFullscreen }) => {
  const t = useContextTranslation('misc');

  return (
    <Swiper
      className="expert-slider"
      navigation
      pagination
      slidesPerView={1}
      on={{
        slideChange: function (e) {
          e.slides.map((slide) => {
            slide.querySelector('video')?.pause();
          });
          void e.slides[e.activeIndex].querySelector('video')?.play();
        },
      }}
    >
      <SwiperSlide className="expert-slide" key="profilePicture">
        <img src={profile.profilePicture} alt={t('profile_picture')} />
      </SwiperSlide>
      {profile.media.map((media) => (
        <SwiperSlide key={media.uri}>
          {media.type === 'video' ? (
            <>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video playsInline controls={false} src={media.uri} loop />
              <div className="btn-wrapper">
                <IonButton
                  className="fullscreen-btn"
                  type="button"
                  fill="outline"
                >
                  <FontAwesomeIcon
                    icon={isFullscreen ? faCompress : faExpand}
                  />
                </IonButton>
              </div>
            </>
          ) : (
            <img src={media.uri} alt={t('media')} />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
});

const ExpertProfilePage: React.FC<{ hideExpertRouteOptions?: boolean }> = ({
  hideExpertRouteOptions,
}) => {
  const t = useContextTranslation('page.expert_profile');
  const beforeContentRef = useRef<ExpertProfileBeforeContentHandle>(null);
  const { expertId } = useParams<ExpertProfileParams>();
  const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false);

  const [adapter] = useInject<IExpertProfileAdapter>(
    injectables.ExpertProfileAdapter,
  );
  const profile = adapter.useExpertProfile(expertId);
  const {
    setExpertId,
    setTreatmentTypeId,
    expertId: contextExpertId,
  } = useExpertContext();
  const { setDate } = useTreatmentBuilder();

  useIonViewWillEnter(() => {
    if (hideExpertRouteOptions) {
      return;
    }
    if (expertId !== contextExpertId) {
      setExpertId(expertId);
      setDate(null);
      setTreatmentTypeId(null);
    }
  }, [contextExpertId, expertId, hideExpertRouteOptions]);

  return (
    <SubPageLayout
      className="expert-profile"
      beforeContent={
        <div className="expert-profile-before-content">
          {profile.value && (
            <ExpertProfileBeforeContent
              ref={beforeContentRef}
              profile={profile.value}
              setIsFullscreen={setIsFullscreen}
              isFullscreen={isFullscreen}
            />
          )}
        </div>
      }
      isFullscreen={isFullscreen}
    >
      <IonLoading isOpen={profile.loading} message={t('loading')} />
      {profile.value && <ExpertProfileInfo profile={profile.value} />}
    </SubPageLayout>
  );
};

export default ExpertProfilePage;
