import './index.scss';

import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  RouteManagerContext,
  useIonViewWillEnter,
} from '@ionic/react';
import * as React from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as ArrowLeftIcon } from 'ui/theme/images/circle-arrow-left-filled.svg';

import Header from '../Header/Header';
import PromoCodeBanner from 'ui/elements/PromoCodeBanner/PromoCodeBanner';

// TODO: fix types

type SubPageLayoutType = {
  beforeContent?: React.ReactNode;
  footerContent?: ({
    showAskToScroll,
  }: {
    showAskToScroll?: boolean;
  }) => React.ReactElement | false | null;
  onGoBack?: (routeBack: () => void) => void;
  className?: string;
  containerClassName?: string;
  isFullscreen?: boolean;
  withBanner?: boolean;
  children: React.ReactNode;
};

const SubPageLayout = forwardRef<HTMLIonContentElement, SubPageLayoutType>(
  function SubPageLayout(
    {
      children,
      onGoBack,
      className,
      containerClassName,
      beforeContent,
      footerContent,
      isFullscreen,
      withBanner,
    },
    scrollRef,
  ) {
    const headerRef = React.useRef<HTMLIonHeaderElement>(null);
    const contentRef = React.useRef<HTMLIonContentElement>(null);
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);

    useImperativeHandle(scrollRef, () => contentRef.current!, []);

    const history = useHistory();
    const routeManager = React.useContext(RouteManagerContext);

    const handleRouteBack = React.useCallback(() => {
      if (routeManager.canGoBack()) {
        routeManager.goBack();
      } else {
        history.replace('/home');
      }
    }, [routeManager, history]);

    const onBackClick = onGoBack
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        () => onGoBack(handleRouteBack)
      : handleRouteBack;

    const [showAskToScroll, setShowAskTosScroll] = useState(false);

    useIonViewWillEnter(() => {
      if (!scrollContainerRef.current) {
        return;
      }

      const scrollOffset =
        window.innerWidth < 1000
          ? window.innerHeight - scrollContainerRef.current.offsetHeight + 65
          : window.innerHeight - scrollContainerRef.current.offsetHeight;

      if (scrollContainerRef.current && scrollOffset < 0) {
        setShowAskTosScroll(true);
      }
    }, [scrollContainerRef.current]);

    return (
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      <IonPage className={`sub-page-layout${className ? ` ${className}` : ''}`}>
        <IonContent
          forceOverscroll={false}
          ref={contentRef}
          scrollEvents
          onIonScroll={(event) => {
            if (!scrollContainerRef.current) {
              return;
            }
            const target = event.target;

            if (
              scrollContainerRef.current &&
              scrollContainerRef.current.offsetHeight -
                event.detail.scrollTop -
                target.clientHeight <
                100
            ) {
              setShowAskTosScroll(false);
            }

            if (!headerRef.current) {
              return;
            }
            if (event.detail.scrollTop > 20) {
              headerRef.current.classList.add('shadow');
              scrollContainerRef.current.classList.add('scrolled');
            } else {
              headerRef.current.classList.remove('shadow');
              scrollContainerRef.current.classList.remove('scrolled');
            }
          }}
          className={isFullscreen ? ' fullScreenContent' : ''}
        >
          <IonHeader className="sub-page-header">
            <Header />
            <div className="action-container mobile ion-hide-lg-up">
              <IonButton
                onClick={onBackClick}
                type="button"
                className="return-btn"
                fill="outline"
              >
                <ArrowLeftIcon />
              </IonButton>
            </div>
          </IonHeader>
          <div
            ref={scrollContainerRef}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            className={`scroll-container${containerClassName ? ` ${containerClassName}` : ''}`}
          >
            <div
              className={`before-content${isFullscreen ? ' fullScreen' : ''}`}
            >
              {beforeContent || <div className="no-content" />}
            </div>
            {withBanner && <PromoCodeBanner atTop />}
            <div className="content-container">
              <div
                className={`action-container desktop ion-hide-lg-down${
                  withBanner ? ' with-banner' : ''
                }`}
              >
                <IonButton
                  onClick={onBackClick}
                  type="button"
                  className="return-btn"
                  fill="outline"
                >
                  <ArrowLeftIcon />
                </IonButton>
              </div>
              {children}
            </div>
          </div>
        </IonContent>
        {footerContent && (
          <IonFooter>{footerContent({ showAskToScroll })}</IonFooter>
        )}
      </IonPage>
    );
  },
);

export default SubPageLayout;
