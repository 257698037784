import type {
  MediaData,
  TreatmentClientData,
  TreatmentDate,
  TreatmentDetails,
  TreatmentLength,
  TreatmentLocation,
  TreatmentType,
} from 'application/types';
import * as React from 'react';
import { useCallback } from 'react';

export interface TreatmentExpert {
  name: string;
  profilePictureUrl: string;
  expertId: string;
  type: TreatmentType;
}

interface TreatmentContextValues {
  type: TreatmentType | null;
  length: TreatmentLength | null;
  location: TreatmentLocation | null;
  date: TreatmentDate | null;
  details: TreatmentDetails | null;
  clientData: TreatmentClientData | null;
  isExpertRoute: boolean;
  locationId: string | null;
  locationMedia: MediaData[] | null;
  expertId: string | null;
  experts: TreatmentExpert[] | null;
  numberOfExperts: number | null;
  minimalPrice: number | null;
  slotTime: Date | null;
  isFirstExpertSelection: boolean;
  isSpaBooking: boolean | null;
}

const defaultValues: TreatmentContextValues = {
  length: null,
  location: null,
  type: null,
  date: null,
  slotTime: null,
  details: null,
  clientData: null,
  isExpertRoute: false,
  locationId: null,
  locationMedia: null,
  expertId: null,
  experts: null,
  numberOfExperts: null,
  isFirstExpertSelection: true,
  isSpaBooking: null,
  minimalPrice: null,
};

interface TreatmentContextValuesWithActions extends TreatmentContextValues {
  setLength: (length: TreatmentLength | null) => void;
  setExpertRoute: (isExpertRoute: boolean) => void;
  setType: (type: TreatmentType | null) => void;
  setLocation: (location: TreatmentLocation | null) => void;
  setDate: (date: TreatmentDate | null) => void;
  setSlotTime: (slotTime: Date | null) => void;
  setDetails: (details: TreatmentDetails | null) => void;
  setClientData: (clientData: TreatmentClientData | null) => void;
  setLocationId: (locationId: string | null) => void;
  setIsSpaBooking: (isSpaBooking: boolean | null) => void;
  setLocationMedia: (locationMedia: MediaData[] | null) => void;
  setExpertId: (expertId: string | null) => void;
  setExperts: (experts: TreatmentExpert[] | null) => void;
  setNumberOfExperts: (numberOfExperts: number | null) => void;
  setIsFirstExpertSelection: (isFirstExpertSelection: boolean) => void;
  setMinimalPrice: (minimalPrice: number | null) => void;
  clearData: () => void;
}

const TreatmentContext = React.createContext<TreatmentContextValuesWithActions>(
  {
    ...defaultValues,
    setLength: () => {},
    setExpertRoute: () => {},
    setType: () => {},
    setLocation: () => {},
    setDate: () => {},
    setSlotTime: () => {},
    setDetails: () => {},
    setClientData: () => {},
    clearData: () => {},
    setLocationId: () => {},
    setIsSpaBooking: () => {},
    setLocationMedia: () => {},
    setExpertId: () => {},
    setExperts: () => {},
    setNumberOfExperts: () => {},
    setIsFirstExpertSelection: () => {},
    setMinimalPrice: () => {},
  },
);

type SetLengthAction = {
  type: 'SET_LENGTH';
  payload: {
    length: TreatmentLength | null;
  };
};

type SetExpertRoute = {
  type: 'SET_EXPERT_ROUTE';
  payload: {
    isExpertRoute: boolean;
  };
};
type SetTypeAction = {
  type: 'SET_TYPE';
  payload: {
    type: TreatmentType | null;
  };
};
type SetLocationAction = {
  type: 'SET_LOCATION';
  payload: {
    location: TreatmentLocation | null;
  };
};
type SetDateAction = {
  type: 'SET_DATE';
  payload: {
    date: TreatmentDate | null;
  };
};
type SetSlotTimeAction = {
  type: 'SET_SLOT_TIME';
  payload: {
    slotTime: Date | null;
  };
};
type SetDetailsAction = {
  type: 'SET_DETAILS';
  payload: {
    details: TreatmentDetails | null;
  };
};
type SetClientDataAction = {
  type: 'SET_CLIENT_DATA';
  payload: {
    clientData: TreatmentClientData | null;
  };
};
type ClearAction = {
  type: 'CLEAR_DATA';
  payload: {
    defaultType: TreatmentType | null;
  };
};

type SetLocationIdAction = {
  type: 'SET_LOCATION_ID';
  payload: {
    locationId: string | null;
  };
};
type SetIsSpaBookingAction = {
  type: 'SET_IS_SPA_BOOKING';
  payload: {
    isSpaBooking: boolean | null;
  };
};

type SetLocationMediaAction = {
  type: 'SET_LOCATION_MEDIA';
  payload: {
    locationMedia: MediaData[] | null;
  };
};

type SetExpertId = {
  type: 'SET_EXPERT_ID';
  payload: {
    expertId: string | null;
  };
};

type SetExperts = {
  type: 'SET_EXPERTS';
  payload: {
    experts: TreatmentExpert[] | null;
  };
};

type SetNumberOfExperts = {
  type: 'SET_NUMBER_OF_EXPERTS';
  payload: {
    numberOfExperts: number | null;
  };
};
type SetIsFirstExpertSelection = {
  type: 'SET_IS_FIRST_EXPERT_SELECTION';
  payload: {
    isFirstExpertSelection: boolean;
  };
};

type SetMinimalPrice = {
  type: 'SET_MINIMAL_PRICE';
  payload: {
    minimalPrice: number | null;
  };
};

type TreatmentAction =
  | SetLengthAction
  | SetTypeAction
  | SetLocationAction
  | SetDateAction
  | SetSlotTimeAction
  | SetDetailsAction
  | SetClientDataAction
  | SetExpertRoute
  | ClearAction
  | SetLocationIdAction
  | SetLocationMediaAction
  | SetExpertId
  | SetExperts
  | SetNumberOfExperts
  | SetIsFirstExpertSelection
  | SetIsSpaBookingAction
  | SetMinimalPrice;

const treatmentReducer: React.Reducer<
  TreatmentContextValues,
  TreatmentAction
> = (prevState, action) => {
  switch (action.type) {
    case 'SET_LENGTH': {
      return { ...prevState, length: action.payload.length };
    }
    case 'SET_EXPERT_ROUTE': {
      return { ...prevState, isExpertRoute: action.payload.isExpertRoute };
    }
    case 'SET_TYPE': {
      // length must be reset if target type does not contain appropriate length
      const length =
        prevState.length &&
        action.payload.type?.prices?.find(
          price => price.length === prevState.length?.length,
        )
          ? prevState.length
          : null;
      return {
        ...prevState,
        type: action.payload.type,
        length,
      };
    }
    case 'SET_LOCATION': {
      // TODO RESET OTHER DATA?
      return {
        ...prevState,
        ...(action.payload.location
          ? { location: { ...prevState.location, ...action.payload.location } }
          : null),
      };
    }
    case 'SET_DATE': {
      return {
        ...prevState,
        date: action.payload.date,
        experts: null,
        type: null,
      };
    }
    case 'SET_SLOT_TIME': {
      return {
        ...prevState,
        slotTime: action.payload.slotTime,
        length: null,
      };
    }
    case 'SET_DETAILS': {
      return { ...prevState, details: action.payload.details };
    }
    case 'SET_CLIENT_DATA': {
      return { ...prevState, clientData: action.payload.clientData };
    }
    case 'CLEAR_DATA': {
      return {
        ...prevState,
        slotTime: null,
        experts: null,
        length: null,
        clientData: null,
        type: null,
        location: null,
        details: null,
        date: null,
        locationId: null,
        locationMedia: null,
        expertId: null,
        numberOfExperts: null,
        isFirstExpertSelection: true,
        isSpaBooking: null,
        minimalPrice: null,
      };
    }
    case 'SET_LOCATION_ID': {
      return {
        ...prevState,
        type: null,
        date: null,
        length: null,
        details: null,
        locationId: action.payload.locationId,
      };
    }
    case 'SET_LOCATION_MEDIA': {
      return {
        ...prevState,
        locationMedia: action.payload.locationMedia,
      };
    }
    case 'SET_EXPERT_ID': {
      return {
        ...prevState,
        expertSlot: null,
        type: null,
        expertId: action.payload.expertId,
      };
    }

    case 'SET_EXPERTS': {
      return {
        ...prevState,
        experts: action.payload.experts,
      };
    }
    case 'SET_NUMBER_OF_EXPERTS': {
      return {
        ...prevState,
        type: null,
        experts: null,
        numberOfExperts: action.payload.numberOfExperts,
      };
    }
    case 'SET_IS_FIRST_EXPERT_SELECTION': {
      return {
        ...prevState,
        isFirstExpertSelection: action.payload.isFirstExpertSelection,
      };
    }
    case 'SET_IS_SPA_BOOKING': {
      return {
        ...prevState,
        isSpaBooking: action.payload.isSpaBooking,
        numberOfExperts: null,
        slotTime: null,
        experts: null,
        type: null,
        date: null,
        length: null,
        details: null,
      };
    }
    case 'SET_MINIMAL_PRICE': {
      return {
        ...prevState,
        minimalPrice: action.payload.minimalPrice,
      };
    }
    default: {
      return prevState;
    }
  }
};

const TreatmentContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(treatmentReducer, defaultValues);

  const setLength = useCallback<TreatmentContextValuesWithActions['setLength']>(
    length => {
      dispatch({
        type: 'SET_LENGTH',
        payload: {
          length,
        },
      });
    },
    [],
  );
  const setExpertRoute = useCallback<
    TreatmentContextValuesWithActions['setExpertRoute']
  >(isExpertRoute => {
    dispatch({
      type: 'SET_EXPERT_ROUTE',
      payload: {
        isExpertRoute,
      },
    });
  }, []);
  const setType = useCallback<TreatmentContextValuesWithActions['setType']>(
    type => {
      dispatch({
        type: 'SET_TYPE',
        payload: {
          type,
        },
      });
    },
    [],
  );
  const setLocation = useCallback<
    TreatmentContextValuesWithActions['setLocation']
  >(location => {
    dispatch({
      type: 'SET_LOCATION',
      payload: {
        location,
      },
    });
  }, []);
  const setDate = useCallback<TreatmentContextValuesWithActions['setDate']>(
    date => {
      dispatch({
        type: 'SET_DATE',
        payload: {
          date,
        },
      });
    },
    [],
  );
  const setSlotTime = useCallback<
    TreatmentContextValuesWithActions['setSlotTime']
  >(slotTime => {
    dispatch({
      type: 'SET_SLOT_TIME',
      payload: {
        slotTime,
      },
    });
  }, []);
  const setDetails = useCallback<
    TreatmentContextValuesWithActions['setDetails']
  >(details => {
    dispatch({
      type: 'SET_DETAILS',
      payload: {
        details,
      },
    });
  }, []);
  const setClientData = useCallback<
    TreatmentContextValuesWithActions['setClientData']
  >(clientData => {
    dispatch({
      type: 'SET_CLIENT_DATA',
      payload: {
        clientData,
      },
    });
  }, []);
  const clearData = useCallback<TreatmentContextValuesWithActions['clearData']>(
    () =>
      dispatch({
        type: 'CLEAR_DATA',
        payload: { defaultType: null },
      }),
    [],
  );

  const setLocationId = useCallback<
    TreatmentContextValuesWithActions['setLocationId']
  >(
    locationId =>
      dispatch({
        type: 'SET_LOCATION_ID',
        payload: { locationId },
      }),
    [],
  );

  const setIsSpaBooking = useCallback<
    TreatmentContextValuesWithActions['setIsSpaBooking']
  >(
    isSpaBooking =>
      dispatch({
        type: 'SET_IS_SPA_BOOKING',
        payload: { isSpaBooking },
      }),
    [],
  );

  const setLocationMedia = useCallback<
    TreatmentContextValuesWithActions['setLocationMedia']
  >(
    locationMedia =>
      dispatch({
        type: 'SET_LOCATION_MEDIA',
        payload: { locationMedia },
      }),
    [],
  );
  const setExpertId = useCallback<
    TreatmentContextValuesWithActions['setExpertId']
  >(
    expertId =>
      dispatch({
        type: 'SET_EXPERT_ID',
        payload: { expertId },
      }),
    [],
  );

  const setExperts = useCallback<
    TreatmentContextValuesWithActions['setExperts']
  >(
    experts =>
      dispatch({
        type: 'SET_EXPERTS',
        payload: { experts },
      }),
    [],
  );

  const setNumberOfExperts = useCallback<
    TreatmentContextValuesWithActions['setNumberOfExperts']
  >(
    numberOfExperts =>
      dispatch({
        type: 'SET_NUMBER_OF_EXPERTS',
        payload: { numberOfExperts },
      }),
    [],
  );

  const setIsFirstExpertSelection = useCallback<
    TreatmentContextValuesWithActions['setIsFirstExpertSelection']
  >(
    isFirstExpertSelection =>
      dispatch({
        type: 'SET_IS_FIRST_EXPERT_SELECTION',
        payload: { isFirstExpertSelection },
      }),
    [],
  );

  const setMinimalPrice = useCallback<
    TreatmentContextValuesWithActions['setMinimalPrice']
  >(
    minimalPrice =>
      dispatch({
        type: 'SET_MINIMAL_PRICE',
        payload: { minimalPrice },
      }),
    [],
  );

  return (
    <TreatmentContext.Provider
      value={{
        ...state,
        setLength,
        setExpertRoute,
        setType,
        setLocation,
        setDate,
        setSlotTime,
        setDetails,
        setClientData,
        setLocationId,
        setIsSpaBooking,
        setLocationMedia,
        setExperts,
        setExpertId,
        setNumberOfExperts,
        setIsFirstExpertSelection,
        setMinimalPrice,
        clearData,
      }}
    >
      {children}
    </TreatmentContext.Provider>
  );
};

export const useTreatmentBuilder = (): TreatmentContextValuesWithActions =>
  React.useContext(TreatmentContext);

export default TreatmentContextProvider;
