import { faCalendar, faStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react';
import type { TreatmentExpert } from 'application/state/TreatmentContext';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { TranslatableFormatter, useContextTranslation } from 'ui/translation';

import BeforeContentGallery, {
  defaultMedia,
} from '../../../../ui/elements/BeforeContentGallery';
import BookingFooterWithChild from '../../../../ui/elements/BookingFooter/BookingFooterWithNextExpertSlots';
import formatLocalizedDate from '../../../../ui/utils/formatLocalizedDate';
import type { TreatmentDate, TreatmentLocation } from '../../../types';
import SearchNextExpert from '../SearchExpertsForTreatment/SearchNextExpert';

// TODO Refactor required
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */

const NEXT_EXPERT_INDEX = 1;
const SelectedExpertSummary = ({
  treatmentDate,
  expert,
}: {
  treatmentDate: Date;
  expert: TreatmentExpert;
}) => {
  const t = useContextTranslation('page.next_expert');
  const formatDate = formatLocalizedDate(treatmentDate, 'cccccc, dd.MM.yyyy');
  return (
    <IonList className="booking-summary-table" mode="ios" lines="full">
      <IonItem detail={false} mode="ios">
        <FontAwesomeIcon icon={faCalendar} />
        <span className="text">{t('time_format', { time: formatDate })}</span>
      </IonItem>

      <IonItem detail={false} mode="ios">
        <FontAwesomeIcon icon={faStar} />
        <span className="text">
          {t('guest_one', { name: expert.name })}
          <TranslatableFormatter value={expert.type?.name} />
        </span>
      </IonItem>
    </IonList>
  );
};

const NextExpertSelection: React.FC = () => {
  const t = useContextTranslation('page.next_expert');

  const history = useHistory();
  const {
    slotTime,
    experts,
    length,
    location,
    date,
    numberOfExperts,
    locationMedia,
    locationId,
    type,
    setIsFirstExpertSelection,
    setExpertId,
    setType,
  } = useTreatmentBuilder();

  const hasSelectedExpert = Boolean(
    numberOfExperts && date && location?.postalCode && experts?.[0].type,
  );

  const onGoBack = (handleGoBack: any) => {
    handleGoBack();
  };

  useIonViewWillEnter(() => {
    setIsFirstExpertSelection(false);
    if (experts?.[1]) {
      setExpertId(experts[1].expertId);
      setType(experts[1].type);
    }
    return () => {
      setIsFirstExpertSelection(true);
    };
  }, []);

  useIonViewWillEnter(() => {
    if (!hasSelectedExpert) {
      history.replace('/treatments');
    }
  }, [hasSelectedExpert, history]);

  const footerContent = () => {
    if (hasSelectedExpert && experts?.[NEXT_EXPERT_INDEX]?.type) {
      return (
        <BookingFooterWithChild
          expertIndex={1}
          onNext={() => {
            history.push('/booking/experts-summary');
          }}
          nextEnabled={Boolean(hasSelectedExpert && slotTime)}
          treatmentDate={{
            numberOfExperts: numberOfExperts as number,
            location: location as TreatmentLocation,
            date: date as TreatmentDate,
            experts,
            locationId,
            treatmentTypeIds: type ? [type.id] : [],
          }}
        />
      );
    }
    return <></>;
  };

  return (
    <SubPageLayout
      beforeContent={
        <BeforeContentGallery
          media={locationMedia || experts?.[0].type.media || defaultMedia}
        />
      }
      className="treatment-selector"
      onGoBack={onGoBack}
      footerContent={() => footerContent()}
      withBanner
    >
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol className="no-padding-top" size="12">
            {date?.date && experts?.[0] && (
              <SelectedExpertSummary
                treatmentDate={date?.date}
                expert={experts[0]}
              />
            )}
          </IonCol>
        </IonRow>

        <IonRow className="ion-justify-content-center">
          {location && (
            <SearchNextExpert
              params={{
                date,
                length,
                location,
                numberOfExperts,
                expertsIds: experts ? [experts?.[0].expertId] : [],
                locationId,
              }}
            />
          )}
        </IonRow>
        {/* // TODO handle when no next expert :D */}
        {!location?.postalCode && (
          <IonRow className="ion-justify-content-center treatment-results-container">
            <IonCol className="no-results no-availabilities" size="12">
              <IonText>
                <p className="booking-info">{t('booking_availability_info')}</p>
              </IonText>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </SubPageLayout>
  );
};

export default NextExpertSelection;
