import type currency from 'currency.js';

export type ObjectID = string;

export interface Pagination {
  pagination: { offset: number; limit: number };
}

export type WithPagination<T> = T & Pagination;

export interface FetchMoreVariables {
  variables: Pagination;
}

export interface HookData<T> {
  loading: boolean;
  error?: Error;
  value: T | null;
  fetchMore?: (variables: FetchMoreVariables) => void;
}

export type WithRefetch<T extends HookData<unknown>> = T & {
  refetch(): void;
};

export interface MediaData {
  type: 'image' | 'video';
  uri: string;
}

export type WithMedia = {
  media: MediaData[];
};

export interface TranslatableStringLanguage {
  lang: 'en' | 'de';
  value: string;
}

// translatable string should have at least one element
export type TranslatableString = TranslatableStringLanguage[] & {
  0: TranslatableStringLanguage;
};

export type Modify<T, R> = Omit<T, keyof R> & R;

export type Arguments<T> = [T] extends [(...args: infer U) => unknown]
  ? U
  : [T] extends [void]
    ? []
    : [T];

export interface TypedEventEmitter<Events> {
  addListener<E extends keyof Events>(event: E, listener: Events[E]): this;

  on<E extends keyof Events>(event: E, listener: Events[E]): this;

  once<E extends keyof Events>(event: E, listener: Events[E]): this;

  prependListener<E extends keyof Events>(event: E, listener: Events[E]): this;

  prependOnceListener<E extends keyof Events>(
    event: E,
    listener: Events[E],
  ): this;

  off<E extends keyof Events>(event: E, listener: Events[E]): this;

  removeAllListeners<E extends keyof Events>(event?: E): this;

  removeListener<E extends keyof Events>(event: E, listener: Events[E]): this;

  emit<E extends keyof Events>(
    event: E,
    ...args: Arguments<Events[E]>
  ): boolean;
}

export interface ExpertBriefData {
  id: string;
  name: string;
  profilePictureUrl: string;
}

export interface TreatmentLength {
  id: ObjectID;
  length: number;
  price: number;
}

export interface TreatmentClientData extends ClientData {
  email: string;
  phoneNumber: string;
}

export interface VoucherClientData extends ClientData {
  email: string;
}

export interface ClientData {
  name: string;
  surname: string;
  companyName?: string;
  locale?: string;
  notificationToken?: string;
  platform?: string;
}

export enum BookingPlace {
  Home = 'home',
  Hotel = 'hotel',
  Company = 'company',
  Event = 'event',
  Endorsement = 'endorsement',
}

export interface TreatmentDetails {
  massageTable: string | null;
  place: BookingPlace | null;
  notes: string;
}

export interface BookingPriceInfoComponent {
  type: 'atHome' | 'treatment' | 'discount' | 'pricingAdjustment' | 'referral';
  value: currency;
}

export enum DiscountStatus {
  NONE = 'none',
  APPLIED = 'applied',
  NOT_FOUND = 'not_found',
  NOT_APPLICABLE = 'not_applicable',
  EXPIRED = 'expired',
  DISABLED = 'disabled',
  NOT_REDEEMABLE = 'not_redeemable',
  USED = 'already_used',
}

export enum VoucherApplianceStatus {
  NONE = 'none',
  APPLIED = 'activated',
  NOT_FOUND = 'not_found',
  EXPIRED = 'expired',
  USED = 'used',
  INACTIVE = 'inactive',
  BELOW_MINIMAL_PRICE = 'below_minimal_price',
}

export enum ReferralApplianceStatus {
  NONE = 'none',
  APPLIED = 'applied',
  NOT_FOUND = 'not_found',
  USED = 'already_used',
  NO_BALANCE = 'no_balance',
}

export interface BookingPriceInfo {
  total: currency;
  totalTax: currency;
  components: BookingPriceInfoComponent[];
  discountStatus?: DiscountStatus;
  voucherStatus?: VoucherApplianceStatus;
  referralStatus?: ReferralApplianceStatus;
  voucherRemainingBalance?: currency;
}

export enum TreatmentTimeRange {
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export interface TreatmentDate {
  date: Date;
  timeRange: TreatmentTimeRange;
}

export interface TreatmentLocation extends Address {
  type: 'atHome';
  isWithoutOffset?: boolean;
}

export interface Address {
  postalCode: string;
  locality: string;
  street: string;
  streetNumber: string;
}

export type BookingConfirmationText = {
  index: number;
  value: TranslatableString;
};

export type Location = {
  id: string;
  address: Address;
  name: TranslatableString;
  media: MediaData[];
  cardPicture: string;
  state: 'enabled' | 'opening_soon';
  cardHeader: {
    xsmall: TranslatableString;
    small: TranslatableString;
    medium: TranslatableString;
    large: TranslatableString;
  };
  cardSubheader: TranslatableString;
  isWithoutOffset?: boolean;
};

export interface TreatmentTypeMedia {
  type: 'image' | 'video';
  uri: string;
}

export interface TreatmentTypeBriefData {
  id: string;
  name: TranslatableString;
}

export interface TreatmentTypeDetails {
  origin: TranslatableString;
  pressure: TranslatableString;
  level: TranslatableString;
  oil: boolean;
}

export interface TreatmentType extends TreatmentTypeBriefData {
  description: TranslatableString;
  media: TreatmentTypeMedia[];
  prices: TreatmentPrice[];
  longDescription?: TranslatableString;
  details?: TreatmentTypeDetails;
}

export interface TreatmentPrice {
  length: number;
  morning: string;
  afternoon: string;
  evening: string;
}

export interface Booking {
  id: ObjectID;
  customId: string;
  price: BookingPriceInfo;
  treatmentType: ObjectID;
  client?: ObjectID;
  expert: ObjectID;
  length: number;
  time: Date;
  createdAt: Date;
  updatedAt: Date;
  token: string;
  status: 'paid' | 'failed' | 'placed' | 'cancelled';
  address: TreatmentLocation;
  clientData: TreatmentClientData;
  details: TreatmentDetails;
  hasReview: boolean;
  isInSpa?: boolean;
}

export interface Cart {
  id: ObjectID;
  customId: string;
  client?: ObjectID;
  clientData: TreatmentClientData;
  status: 'waitingForPayment' | 'paid';
  token: string;
}

export type WithExpert<T> = Omit<T, 'expert'> & {
  expert: ExpertBriefData;
};

export type WithTreatmentType<T> = Omit<T, 'treatmentType'> & {
  treatmentType: TreatmentType;
};
