import { gql, useQuery } from '@apollo/client';
import type {
  Location,
  ObjectID,
  TranslatableString,
  BookingConfirmationText,
} from 'application/types';

import type { HookData } from '../../types';
// TODO: refactor required
/* eslint-disable @typescript-eslint/no-misused-promises */

const GET_ALL_LOCATIONS = gql`
  query GetAllLocations($pagination: Pagination!) {
    locations(pagination: $pagination) {
      nodes {
        state
        cardPicture
        cardSubheader {
          lang
          value
        }
        cardHeader {
          xsmall {
            lang
            value
          }
          small {
            lang
            value
          }
          medium {
            lang
            value
          }
          large {
            lang
            value
          }
        }
        id
        name {
          lang
          value
        }
        media {
          type
          uri
        }
        address {
          postalCode
          locality
          street
          streetNumber
        }
        isWithoutOffset
      }
    }
  }
`;

export type LocationWithDetails = Location & {
  details: {
    locationHours: TranslatableString;
    features: TranslatableString;
  };
  description: TranslatableString;
  confirmationStepTexts: BookingConfirmationText;
};
export const GET_LOCATION_BY_ID = gql`
  query GetLocationById($id: ID!) {
    location(id: $id) {
      state
      id
      cardPicture
      cardSubheader {
        lang
        value
      }
      name {
        lang
        value
      }
      media {
        type
        uri
      }
      details {
        locationHours {
          lang
          value
        }
        features {
          lang
          value
        }
      }
      address {
        postalCode
        locality
        street
        streetNumber
      }
      description {
        value
        lang
      }
      confirmationStepTexts {
        index
        value {
          lang
          value
        }
      }
    }
  }
`;

export const WITHOUT_OFFSET_TREATMENT_LENGTH = 10;

const LOCATION_PER_PAGE = 10;
export const useLocations = (): HookData<Location[]> => {
  const query = useQuery<{
    locations: {
      nodes: Location[];
    };
  }>(GET_ALL_LOCATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      pagination: {
        limit: LOCATION_PER_PAGE,
        offset: 0,
      },
    },
  });

  return {
    value: query.data?.locations.nodes || [],
    loading: query.loading,
    fetchMore: (fetchMoreParams) =>
      query.fetchMore({
        ...fetchMoreParams,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const ids =
            fetchMoreResult?.locations?.nodes.reduce(
              (acc, result) => {
                acc[result.id] = true;
                return acc;
              },
              {} as { [key: string]: boolean },
            ) || {};
          return {
            locations: {
              nodes: [
                ...prev.locations.nodes.filter((el) => !ids[el.id]),
                ...(fetchMoreResult?.locations.nodes || []),
              ],
            },
          };
        },
      }),
  };
};

export const useLocationById = (
  locationId: ObjectID,
): HookData<LocationWithDetails> => {
  const query = useQuery<{ location: LocationWithDetails }>(
    GET_LOCATION_BY_ID,
    {
      variables: {
        id: locationId,
      },
      fetchPolicy: 'network-only',
    },
  );

  return {
    value: query.data?.location || null,
    loading: query.loading,
  };
};
