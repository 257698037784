import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import {
  useContextTranslation,
  useTranslateOptionalString,
} from 'ui/translation';

import useBreakpoint from '../../../ui/utils/useBreakpoints';
import { useTreatmentBuilder } from '../../state/TreatmentContext';
import type { Location } from '../../types';

interface LocationProps {
  location: Location;
  setLocationDetailsId: () => void;
}

const LocationCard = ({
  location,
  setLocationDetailsId,
}: LocationProps): JSX.Element => {
  const t = useContextTranslation('page.locations');
  const translate = useTranslateOptionalString();

  const breakpoint = useBreakpoint();

  const { setLocationId, setLocation, setLocationMedia } =
    useTreatmentBuilder();
  const history = useHistory();

  const handleLocationBooking = () => {
    if (location.state !== 'enabled') {
      return;
    }
    setLocation({
      type: 'atHome',
      locality: location.address.locality,
      street: location.address.street,
      postalCode: location.address.postalCode,
      streetNumber: location.address.streetNumber,
      isWithoutOffset: Boolean(location.isWithoutOffset),
    });
    setLocationId(location.id);
    setLocationMedia(location.media);
    history.push('/treatments');
  };

  return (
    <div className="location-card">
      <IonButton
        onClick={setLocationDetailsId}
        type="button"
        className="location-card__details"
        fill="outline"
      >
        {t('details')}
      </IonButton>
      <img src={location.cardPicture} alt="Soulhouse Hamburg" />
      <div className="location-card__caption">
        <div className="caption-text">
          <span className="name">
            {translate(
              location.cardHeader[breakpoint]?.length
                ? location.cardHeader[breakpoint]
                : location.cardHeader.xsmall,
            )}
          </span>
          <span className="locality">{translate(location.cardSubheader)}</span>
        </div>
        <button
          className="location-card__status"
          onClick={handleLocationBooking}
          type="button"
        >
          {t(location.state)}
        </button>
      </div>
    </div>
  );
};

export default LocationCard;
