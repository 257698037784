import React, { useState } from 'react';

export interface ExpertContextState {
  expertId: string | null;
  treatmentTypeId: string | null;
}

export interface ExpertContextMethods {
  setExpertId: (expertId: string | null) => void;
  setTreatmentTypeId: (treatmentTypeId: string | null) => void;
}

export type ExpertContextValues = ExpertContextState & ExpertContextMethods;

const ExpertContext = React.createContext<ExpertContextValues>({
  setExpertId: () => {},
  setTreatmentTypeId: () => {},
  expertId: null,
  treatmentTypeId: null,
});

export const useExpertContext = (): ExpertContextValues =>
  React.useContext(ExpertContext);

const ExpertContextProvider: React.FC = ({ children }) => {
  const [expertId, setExpertId] = useState<string | null>(null);
  const [treatmentTypeId, setTreatmentTypeId] = useState<string | null>(null);

  return (
    <ExpertContext.Provider
      value={{
        expertId,
        treatmentTypeId,
        setExpertId,
        setTreatmentTypeId,
      }}
    >
      {children}
    </ExpertContext.Provider>
  );
};

export default ExpertContextProvider;
