import './bookingSummary.scss';

import { IonCol, IonGrid, IonRow, useIonViewWillEnter } from '@ionic/react';
import injectables from 'application/injectables';
import BookingDetailsForm from 'application/pages/BookingSummary/BookingDetailsForm';
import type { ITrackingService } from 'application/services/ITrackingService';
import { useAuth } from 'application/state/AuthProvider';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { TreatmentDetails } from 'application/types';
import { BookingPlace } from 'application/types';
import type { FormikHelpers } from 'formik';
import { FormikProvider, useFormik } from 'formik';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory } from 'react-router';
import BookingSummaryFooter from 'ui/elements/BookingSummaryFooter';
import BookingSummaryTable from 'ui/elements/BookingSummaryTable';
import SubPageLayout from 'ui/layout/SubPageLayout';
import HeaderProfilePicture from 'ui/layout/SubPageLayout/HeaderProfilePicture';
import { useContextTranslation } from 'ui/translation';
import * as Yup from 'yup';

import BeforeContentGallery, {
  defaultMedia,
} from '../../../ui/elements/BeforeContentGallery';
import MultipleExpertsTreatmentLengthSelector from '../Treatments/MultipleExperts/MultipleExpertsTreatmentLengthSelector';

// TODO: refactor required
/* eslint-disable  @typescript-eslint/no-floating-promises */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-misused-promises */

type TreatmentDetailsValues = Omit<TreatmentDetails, 'massageTable'> & {
  massageTable: string | null;
};

const BookingSummary: React.FC = () => {
  const {
    slotTime,
    experts,
    date,
    length,
    location,
    type,
    numberOfExperts,
    locationMedia,
    details,
    isExpertRoute,
    locationId,
    setDetails,
    setLength,
  } = useTreatmentBuilder();
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const t = useContextTranslation('page.booking_summary');
  const [trackingService] = useInject<ITrackingService>(
    injectables.services.TrackingService,
  );
  const [expandedLength, setExpandedLength] = React.useState<boolean>(false);

  const isSingleExpert = Number(numberOfExperts) < 2;
  const hasCompleteData = Boolean(
    isSingleExpert && slotTime && date && location && experts?.[0].type,
  );

  const EXPERT_INDEX = 0;

  React.useEffect(() => {
    if (slotTime && location && experts?.[0] && length) {
      trackingService.trackEvent({
        name: 'treatment_slot_selected',
        data: {
          expert: experts[0],
          slotTime,
          location,
          length,
        },
      });
    }
  }, [slotTime, location, type]);

  const onSubmit = React.useCallback<
    (
      values: TreatmentDetailsValues,
      helpers: FormikHelpers<TreatmentDetailsValues>,
    ) => any
  >(
    (values, helpers) => {
      helpers.setSubmitting(false);
      setDetails({
        ...values,
        massageTable: values.massageTable,
      });
      if (isAuthenticated && locationId) {
        history.push('/booking/payment');
        return;
      }
      if (isAuthenticated) {
        history.push('/booking/address');
        return;
      }
      history.push('/booking/contact');
    },
    [history, isAuthenticated],
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        massageTable: Yup.string().required(),
        notes: Yup.string().max(1000, t('form.notes_too_long')).optional(),
      }),
    [t],
  );

  const initDetails = React.useMemo(() => {
    if (locationId) {
      return {
        massageTable: '0',
        place: BookingPlace.Hotel,
        notes: '',
      };
    }
    if (details) {
      return {
        ...details,
      };
    }
    return {
      massageTable: null,
      notes: '',
      place: BookingPlace.Home,
    };
  }, [details, locationId]);

  const formik = useFormik<TreatmentDetailsValues>({
    initialValues: initDetails,
    validationSchema,
    onSubmit,
    validateOnMount: true,
  });

  useIonViewWillEnter(() => {
    if (!hasCompleteData) {
      history.replace('/treatments');
    }
    if (!details) {
      formik.resetForm();
    }
    formik.validateField('massageTable');
  }, [hasCompleteData, history, details]);

  return (
    <SubPageLayout
      className="booking-summary"
      beforeContent={
        <BeforeContentGallery
          media={locationMedia || experts?.[0].type.media || defaultMedia}
        />
      }
      footerContent={() =>
        slotTime && (
          <BookingSummaryFooter
            nextEnabled={
              !isExpertRoute ? formik.isValid && !!length : formik.isValid
            }
            onNext={() => {
              formik.submitForm();
            }}
          />
        )
      }
    >
      {slotTime && location && type && experts?.length && (
        <>
          <HeaderProfilePicture experts={experts} />
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol className="no-padding-top" size="12">
                <h4>{t('header')}</h4>
                <BookingSummaryTable
                  slotTime={slotTime}
                  expert={experts[EXPERT_INDEX]}
                  location={location}
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12">
                <h4>{t('form_header')}</h4>
                {date?.timeRange && (
                  <MultipleExpertsTreatmentLengthSelector
                    locationId={locationId || undefined}
                    slotTime={slotTime}
                    onClick={() => {
                      setExpandedLength((prev) => !prev);
                    }}
                    expanded={expandedLength}
                    treatmentLength={length}
                    treatmentTimeRange={date.timeRange}
                    experts={experts}
                    onSelect={(selectedLength) => {
                      setLength(selectedLength);
                      setExpandedLength(false);
                    }}
                  />
                )}
                <FormikProvider value={formik}>
                  <BookingDetailsForm inSpa={Boolean(locationId)} />
                </FormikProvider>
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      )}
    </SubPageLayout>
  );
};

export default BookingSummary;
