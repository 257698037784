import './treatment.scss';

import {
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
} from '@ionic/react';
import ExpertProfileModal from 'application/pages/ExpertProfile/ExpertProfileModal';
import ResultsWithAvailabilities, {
  TreatmentTypeDetailsModal,
} from 'application/pages/Treatments/TreatmentResult/ResultsWithAvabilities';
import type { ObjectID, TreatmentLocation } from 'application/types';
import * as React from 'react';
import { useContextTranslation } from 'ui/translation';

import { useTreatmentBuilder } from '../../state/TreatmentContext';
import BookingRequestForm from './BookingRequest/BookingRequestForm';
import { useMinimalPriceForExpert } from './DateModal/dateModalUtils';
import type { AvailableExpertsQuery } from './ITreatmentAdapter';

// TODO Refactor required
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */

type BookingModalDetailsContext = {
  setTreatmentTypeDetailsId: (treatmentTypeId: ObjectID | null) => void;
  setExpertId: (expertId: ObjectID | null) => void;
};

export const TreatmentTypeDetails =
  React.createContext<BookingModalDetailsContext>({
    setTreatmentTypeDetailsId: () => {},
    setExpertId: () => {},
  });

const RESULTS_PER_PAGE = 5;

const TreatmentResults = ({
  results,
  page,
  location,
  timeRange,
  setPage,
}: {
  results: any;
  page: number;
  location: TreatmentLocation;
  timeRange: any;
  setPage: any;
}): JSX.Element => {
  const mt = useContextTranslation('misc');
  const { minimalPrice } = useTreatmentBuilder();

  const getMinimalPrice = useMinimalPriceForExpert();

  const [typeDetailsId, setTreatmentTypeDetailsId] = React.useState<
    string | null
  >(null);
  const [expertId, setExpertId] = React.useState<string | null>(null);

  const disabled = Boolean(
    results.value &&
      results.value.length < page * RESULTS_PER_PAGE &&
      !results.loading,
  );

  const infiniteScroll = React.useRef<HTMLIonInfiniteScrollElement>(null);
  const wasTriggered = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (wasTriggered.current) {
      // eslint-disable-next-line no-console
      infiniteScroll.current?.complete().catch(console.error);
      wasTriggered.current = false;
    }
  }, [results.value]);

  const expertsList = results.value || [];

  return (
    <TreatmentTypeDetails.Provider
      value={{ setTreatmentTypeDetailsId, setExpertId }}
    >
      <IonRow className="ion-justify-content-center treatment-results-container">
        {expertsList.map((result: AvailableExpertsQuery) => (
          <IonCol size="12" key={result.id}>
            <ResultsWithAvailabilities
              result={result}
              price={
                minimalPrice ||
                getMinimalPrice(result.treatmentTypes, timeRange)
              }
            />
          </IonCol>
        ))}
        {expertsList.length === 0 && !results.loading && location && (
          <IonCol className="no-results no-experts" size="12">
            <BookingRequestForm address={location} />
          </IonCol>
        )}
        <div>
          <IonInfiniteScroll
            ref={infiniteScroll}
            threshold="10%"
            disabled={disabled}
            onIonInfinite={() => {
              results?.fetchMore?.({
                variables: {
                  pagination: {
                    offset: RESULTS_PER_PAGE * page,
                    limit: RESULTS_PER_PAGE,
                  },
                },
              });
              wasTriggered.current = true;
              setPage((p: number) => p + 1);
            }}
          >
            <IonInfiniteScrollContent loadingText={mt('loading')} />
          </IonInfiniteScroll>
        </div>
        <TreatmentTypeDetailsModal
          treatmentTypeId={typeDetailsId}
          setTreatmentTypeDetails={setTreatmentTypeDetailsId}
        />
        <ExpertProfileModal
          expertId={expertId || ''}
          setExpertId={setExpertId}
        />
      </IonRow>
    </TreatmentTypeDetails.Provider>
  );
};

export default TreatmentResults;
